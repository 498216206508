<template>
  <div class="partner-dropdown">
    <slot name="label">
      <label :for="id" :v-text="labelText" />
    </slot>
    <div>
      <select :id="id" :value="value" @input="handleChange">
        <option
          v-for="option in options"
          :value="option.value"
          :disabled="option.disabled"
          :key="option.value"
          v-text="option.text"
        />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerDropdown',
  props: {
    value: {
      required: true,
    },
    id: {
      required: true,
      default() {
        return `partner-dropdown-${Math.floor(Math.random() * 10000)}`;
      },
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [
          {
            text: 'First Option',
            value: null,
            disabled: true,
          },
        ];
      },
    },
    labelText: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value || null);
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-dropdown {
  margin-top: 43px;
  margin-bottom: 32px;
  label {
    font-size: 12px;
    font-weight: 600;
    @media (min-width: 750px) {
      font-size: 19px;
    }
  }
  select {
    width: 100%;
    color: #8a8f9d;
    border: 3px solid #011452;
    border-radius: 50px;
    height: 50px;
    font-size: 12px;
    text-indent: 20px;
    background: url('~@/assets/images/Global/blue-carat.svg?external') right 1.7em center/19px 11px no-repeat;
    appearance: none;
    margin-top: 12px;
    padding-right: 55px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media(min-width: 750px){
      font-size: 20px;
      height: 73px;
    }
  }
}
</style>
